import React from 'react'
import { Link } from "gatsby";

import TEDxKIContainer from './tedxki-container'
import SocialIcons from './social-media'
import * as styles from './footer.module.css'

const Footer = () => (
  <TEDxKIContainer as="footer">
    <div className={styles.tedxki_container}>
      <div className={styles.item1}>
        <div className="contact-us__form sub-item sub-item-1">
          <p>Contact Us</p>
          <a href="mailto:executiveteam@tedxki.com"><span>executiveteam@tedxki.com</span></a>
        </div>

        <div>
          <p>Follow us on our social media</p>
          <SocialIcons />
        </div>
      </div>

      <div className={styles.item2}>
        <Link to="/sponsors/"><span>Are you our new Sponsor? ></span></Link>
      </div>

      <div>
        TEDxKI 2025.
        This independent <a href="https://www.ted.com/about/programs-initiatives/tedx-program">TEDx</a> event is operated under license from <a href="https://www.ted.com/">TED</a>.
      </div>
    </div>
  </TEDxKIContainer>
)

export default Footer
